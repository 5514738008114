import styled from 'styled-components';

import { Tab, Tabs } from '@material-ui/core';

export const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.hqo.secondary};
  }
`;

export const StyledTab = styled(Tab)`
  &.Mui-selected {
    color: ${({ theme }) => theme.colors.hqo.secondary};
  }
`;
