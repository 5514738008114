import React from 'react';
import {
  InfoMessageWrapper, InfoMessageContent, InfoMessageHeading, InfoMessageMessage,
} from './styles';

export const InfoMessage = () => (
  <InfoMessageWrapper>
    <InfoMessageContent>
      <InfoMessageHeading data-testid="info-message-heading">Create Your Perfect Ambiance!</InfoMessageHeading>
      <InfoMessageMessage data-testid="info-message-message">
        Set up rooms and scenes via your Lutron account to tailor your lights and shades
        settings. Start customizing now!
      </InfoMessageMessage>
    </InfoMessageContent>
  </InfoMessageWrapper>
);
