import React, { useMemo, useState } from 'react';
import { MiniappSdkDemoInput } from 'pages/miniapp-sdk-demo/miniapp-sdk-demo-input';
import { MiniappSdkMethodDemo } from 'pages/miniapp-sdk-demo/miniapp-sdk-method-demo';

export const MiniappSdkStorageGetDemo = (): JSX.Element => {
  const [storageKey, setStorageKey] = useState('');
  const args = useMemo(() => [storageKey], [storageKey]);

  return (
    <MiniappSdkMethodDemo actionGroup="storage" action="get" eventName="getStorageResponse" args={args}>
      <MiniappSdkDemoInput
        value={storageKey}
        onChange={setStorageKey}
        placeholder="Storage Key"
        dataTestId="storageGetKey"
      />
    </MiniappSdkMethodDemo>
  );
};
