import { useState, useCallback, SyntheticEvent } from 'react';

export const useAccordion = (initialPanel: string | false) => {
  const [expanded, setExpanded] = useState<string | false>(initialPanel);

  const handleChange = useCallback((panel: string) => (_event: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  }, []);

  return { expanded, handleChange };
};
