import { Button, Paper } from '@hqo/react-components-library';
import styled from 'styled-components';

export const Card = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
  align-items: flex-start;
`;

export const StyledButton = styled(Button)`
  height: auto;
`;
