import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchBridges } from '../../services/lutronService';
import { bridgeState, useUserSnapshot } from '../../valtioStore';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { Container } from '../../hocs/shared-styles';

export const AppRoot = (): JSX.Element => {
  const { user } = useUserSnapshot();
  const navigate = useNavigate();
  const [fetchCompleted, setFetchCompleted] = useState(false);

  useEffect(() => {
    const fetchAndNavigate = async () => {
      try {
        const response = await fetchBridges(user.uuid);
        if (response.error) {
          navigate('/entry', { replace: true });
        } else {
          bridgeState.items = response.data;
          navigate('/list-devices/caseta', { replace: true });
        }
      } catch {
        navigate('/entry', { replace: true });
      } finally {
        setFetchCompleted(true);
      }
    };

    if (!fetchCompleted) {
      fetchAndNavigate();
    }
  }, [user.uuid, navigate, fetchCompleted]);

  return (
    <Container isLoading={false}>
      <Spinner size="10em" color={defaultTheme.colors.$primary} />
    </Container>
  );
};
