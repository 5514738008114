import * as React from 'react';
import styled from 'styled-components';

import { SwipeableDrawer, Typography } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import MuiSlider from '@material-ui/core/Slider';

import { skeletonAnimation } from 'components/skeleton/styles';
import { Close } from '@hqo/react-components-library';

/* eslint-disable react/jsx-props-no-spreading */

export const StyledDrawer = styled(SwipeableDrawer)`
  .MuiDrawer-paper {
    border-radius: 8px 8px 0 0;
    height: 100% !important;
  }
`;

export const RoomTitle = styled(Typography).attrs({ variant: 'h4' })`
  font-size: 13px !important;
  margin-top: -24px !important;
`;

export const DrawerTitle = styled(Typography).attrs({ variant: 'h4' })`
  font-size: 17px !important;
  font-weight: 500 !important;
  margin-top: -24px !important;
`;

export const DrawerTitleSkeleton = styled.div`
  width: 130px;
  height: 14px;
  ${skeletonAnimation};
`;

export const DrawerControls = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const DrawerHandle = styled.div`
  width: 60px;
  height: 5.984px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedMediumGrey} !important;
`;

export const PlaceholderControl = styled.div`
  width: 20px;
`;

export const StyledCloseIcon = styled(Close)`
  color: ${({ theme }) => theme.colors.$greyDark};
`;

export const DisplayWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  height: 95vh;
`;

export const IconWrapper = styled.div`
  height: 52px;
  width: 52px;
`;

export const SliderSkeleton = styled.div`
  height: 60%;
  width: 160px;
  border-radius: 32px !important;
  ${skeletonAnimation};
`;

const generateSliderStyles = (theme: Theme, inverted = false) => ({
  root: {
    height: '487px !important',
    '&:after': {
      content: '""',
      position: 'absolute',
      height: '100%',
      width: '160px',
      marginLeft: '-100px',
      marginTop: '-20px',
      borderRadius: '52px',
      border: '20px solid white',
      zIndex: 1,
    },
  },
  track: {
    width: '160px !important',
    background: inverted
      ? theme.palette.grey[300]
      : 'linear-gradient(180deg, rgba(255, 191, 0, 0.90) 0.12%, rgba(255, 191, 0, 0.00) 131.8%)',
    marginLeft: '-80px !important',
    zIndex: 0,
  },
  rail: {
    width: '160px !important',
    marginLeft: '-80px !important',
    background: inverted
      ? 'linear-gradient(181deg, #252A2F 0.55%, rgba(37, 42, 47, 0.00) 441.65%)'
      : theme.palette.grey[300],
    zIndex: -1,
    opacity: inverted ? 1 : 0.38,
  },
  thumb: {
    height: 26,
    width: 92,
    marginBottom: '-13px !important',
    marginLeft: '-46px !important',
    borderRadius: 100,
    zIndex: 2,
    border: '1px solid var(--system-grey-dark-alt-31, #878E97)',
    background: 'white',
    boxShadow: '0px 0px 1px 0px rgba(45, 51, 56, 0.10), 0px 2px 4px 0px rgba(45, 51, 56, 0.12)',
  },
});

const CustomSlider = withStyles((theme) => generateSliderStyles(theme))(MuiSlider);
const CustomInvertedSlider = withStyles((theme) => generateSliderStyles(theme, true))(MuiSlider);

interface SliderProps {
  value: number;
  onChange: (event: React.ChangeEvent<unknown>, value: number | number[]) => void;
}

export const Slider = (props: SliderProps) => <CustomSlider {...props} orientation="vertical" />;

export const InvertedSlider = (props: SliderProps) => (
  <CustomInvertedSlider {...props} orientation="vertical" />
);
