import styled from 'styled-components';

export const InfoMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  text-align: center;
`;

export const InfoMessageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin: 0 10%;
  max-width: 460px;
`;

export const InfoMessageHeading = styled.div`
  color: #696E72;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
`;

export const InfoMessageMessage = styled.div`
  color: #696E72;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`;
