import { Input } from '@hqo/react-components-library';
import React, { useCallback } from 'react';

interface MiniappSdkDemoInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  dataTestId: string;
}

export const MiniappSdkDemoInput = ({
  value,
  placeholder,
  onChange: handleChange,
  dataTestId,
}: MiniappSdkDemoInputProps): JSX.Element => {
  const handleChangeEvent = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.value),
    [handleChange],
  );

  return <Input value={value} onChange={handleChangeEvent} placeholder={placeholder} dataTestId={dataTestId} />;
};
