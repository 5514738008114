export type DeviceType = 'Shade' | 'Light';

export const enum ControlType {
  Dimmed = 'Dimmed',
  Switched = 'Switched',
  Shade = 'Shade',
  ShadeWithTilt = 'ShadeWithTilt',
  ShadeWithTiltWhenClosed = 'ShadeWithTiltWhenClosed',
}

export type ControlTypeKey = keyof typeof ControlType;
export type ControlTypeValue = (typeof ControlType)[ControlTypeKey];

export type Device = {
  name: string;
  type: DeviceType;
  controlType: ControlTypeValue;
  zone: number;
};

export type Room = {
  name: string;
  devices: Device[];
};
