import React from 'react';

import { ScenesWrapper } from 'components/scenes/styles';

import {
  sendSetSceneRequest,
  useSceneSnapshot,
} from 'valtioStore';
import { Button } from '@material-ui/core';
import { Scene } from '../../types/Scene';
import { InfoMessage } from '../info-message/info-message';

export const Scenes = (): JSX.Element => {
  const { items } = useSceneSnapshot();

  const handleSceneClick = (id: number) => async () => {
    await sendSetSceneRequest(id);
  };

  if (!items) return null;

  return (
    <ScenesWrapper data-testid="scenes-list">
      {items.length > 0 ? (
        items.map((scene: Scene) => (
          <Button variant="contained" size="large" key={scene.id} onClick={handleSceneClick(scene.id)}>
            {scene.name}
          </Button>
        ))
      ) : (
        <InfoMessage />
      )}
    </ScenesWrapper>
  );
};
