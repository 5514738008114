/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import {
  Drawer,
  Switch,
  SwitchClassKey,
  SwitchProps,
  Theme,
  Typography,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    border-radius: 8px 8px 0 0;
    height: 30% !important;
  }
`;

export const DrawerContent = styled.div`
  display: flex;
  padding: 24px 16px 10px 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const RoomTitle = styled(Typography).attrs({ variant: 'h4' })`
  font-size: 13px !important;
  margin-top: -24px !important;
`;

export const DrawerTitle = styled(Typography).attrs({ variant: 'h4' })`
  font-size: 1.75rem !important;
  font-weight: 500 !important;
`;

interface Props extends SwitchProps {
  classes?: Partial<ClassNameMap<SwitchClassKey>> & {
    focusVisible?: string;
  };
}

export const StyledSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 88,
      height: 48,
      padding: '2px',
      margin: theme.spacing(2),
    },
    switchBase: {
      padding: 0,
      top: '3px',
      left: '4px',
      '&$checked': {
        transform: 'translateX(38px)',
        color: theme.palette.common.white,
        '& + $track': {
          background:
            'linear-gradient(270deg, rgba(255, 191, 0, 0.90) 0.12%, rgba(255, 191, 0, 0.00) 131.8%)',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 42,
      height: 42,
    },
    track: {
      borderRadius: 48 / 2,
      border: 0,
      backgroundColor: theme.palette.grey[300],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
)(({ classes, ...props }: Props) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
));
