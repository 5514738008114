import styled from 'styled-components';

export const ScenesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 16px 10px 16px;
  height: 70vh;
  overflow: scroll;

  button {
    width: 100%;
    height: 56px;
    border: 0;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.universal.white};
    padding: 16px;
    box-shadow: 0 2px 10px 0 rgba(54, 54, 54, 0.20);
    justify-content: flex-start;
    text-transform: none;

    &:hover {
      background: ${({ theme }) => theme.colors.greys.lightGrey3};
    }

    &:active, &:focus {
      background: ${({ theme }) => theme.colors.universal.white};
      box-shadow: 0 2px 10px 0 rgba(54, 54, 54, 0.20);
    }
  }
`;
