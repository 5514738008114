import React, { useEffect, useCallback } from 'react';

import { MenuItem } from '@material-ui/core';
import { SelectWrapper, StyledSelect } from 'components/bridges/styles';

import { useBridgeSnapshot, useBridgeDerivedSnapshot, bridgeState } from 'valtioStore';
import { fetchBridgesData } from '../../valtioStore/bridge';

export const Bridges = (): JSX.Element => {
  const { selected } = useBridgeDerivedSnapshot();
  const { items: bridges } = useBridgeSnapshot();

  useEffect(() => {
    (async () => {
      if (bridges.length === 0) {
        await fetchBridgesData();
      }
    })();
  }, []);

  const handleBridgeChange = useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) => {
      const pick = bridges.findIndex((b) => b.macAddress === e.target.value);
      bridgeState.index = pick;
    },
    [bridges],
  );

  if (bridges?.length === 0) return null;

  return (
    <SelectWrapper data-testid="select-bridges">
      <StyledSelect value={selected?.macAddress || ''} onChange={handleBridgeChange}>
        {bridges?.map(({ macAddress, name }) => (
          <MenuItem key={macAddress} value={macAddress}>
            {name}
          </MenuItem>
        ))}
      </StyledSelect>
    </SelectWrapper>
  );
};
