import { useCallback, useState } from 'react';
import Cookies from 'js-cookie';
import { updateUserUUIDState, useUserSnapshot } from 'valtioStore';

const getUUIDFromStorage = (storage: Storage | typeof Cookies, key: string): string | null => {
  try {
    if (storage) {
      return storage instanceof Storage ? storage.getItem(key) : Cookies.get(key);
    }
  } catch (e) {
    return null;
  }
  return null;
};

const useUUIDStorageManager = () => {
  const { user } = useUserSnapshot();
  const fetchUserUUID = () => user?.uuid
    || getUUIDFromStorage(sessionStorage, 'userUUID')
    || getUUIDFromStorage(localStorage, 'userUUID')
    || getUUIDFromStorage(Cookies, 'userUUID');

  const [userUUID, setUserUUID] = useState(fetchUserUUID());

  const updateUserUUID = useCallback((newUserUUID: string) => {
    sessionStorage.setItem('userUUID', newUserUUID);
    localStorage.setItem('userUUID', newUserUUID);
    if (typeof Cookies !== 'undefined') {
      Cookies.set('userUUID', newUserUUID);
    }
    setUserUUID(newUserUUID);
    updateUserUUIDState(newUserUUID);
  }, []);

  const removeUserUUID = useCallback(() => {
    sessionStorage.removeItem('userUUID');
    localStorage.removeItem('userUUID');
    if (typeof Cookies !== 'undefined') {
      Cookies.remove('userUUID');
    }
    updateUserUUIDState(null);
  }, []);

  return {
    userUUID,
    fetchUserUUID,
    updateUserUUID,
    removeUserUUID,
  };
};

export default useUUIDStorageManager;
