import { proxy, useSnapshot as useValtioSnapshot } from 'valtio';
import { derive } from 'valtio/utils';
import { state as userState } from '../user';
import { fetchBridges } from '../../services/lutronService';

export type BridgeFamily = 'Caseta' | 'Homeworks';

export type Bridge = {
  name: string;
  type: BridgeFamily;
  macAddress: string;
};

export const state = proxy<{ items: Bridge[]; selected: Bridge; index: number }>({
  items: [],
  index: 0,
  /*
   * @returns The current Bridge selection
   */
  get selected() {
    return this.items[this.index];
  },
  /*
   * Sets new selection
   */
  set selected(value) {
    this.index = value;
  },
});

export const derivedState = derive({
  selected: (get) => get(state).selected, // model
});

export const fetchBridgesData = async () => {
  const { user } = userState;
  if (!user.uuid) return;
  const { data } = await fetchBridges(user.uuid);
  state.items = data;
};

export const useSnapshot = () => useValtioSnapshot(state);
export const useDerivedSnapshot = () => useValtioSnapshot(derivedState);
