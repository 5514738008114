import { proxy, useSnapshot as useValtioSnapshot } from 'valtio';
import { User } from '../../types/User';

// Define a state with a user object of type User
export const state = proxy<{ user: User }>({
  user: { uuid: null },
});

// Function to update the user's UUID
export const updateUserUUIDState = (uuid: string) => {
  state.user.uuid = uuid;
};

export const useSnapshot = () => useValtioSnapshot(state);
