import styled from 'styled-components';

import { Select } from '@material-ui/core';

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  font-weight: 500 !important;
  &&::after {
    border-bottom: none;
  }
  &&::before {
    border-bottom: none !important;
  }
  &:active, &:focus {
    background: ${({ theme }) => theme.colors.universal.white};
  }
`;
