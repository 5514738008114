/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 16px;
  padding: 160px 0;
  overflow: scroll;
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
`;

export const EmptyTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

export const EmptyDescription = styled(Typography).attrs({ variant: 'body1' })`
  text-align: center;
`;
