import { useSnapshot as useValtioSnapshot } from 'valtio';
import { fetchScenes, setScene } from '../../services/lutronService';
import { derive } from 'valtio/utils';
import { derivedState as derivedBridgeState } from '../bridge';
import { state as userState } from '../user';

export const scenes = derive({
  items: async (get) => {
    const { selected } = get(derivedBridgeState);
    const { user } = get(userState);

    if (!selected) return [];

    const { data } = await fetchScenes(user?.uuid, selected.macAddress);
    return data;
  },
});

export const sendSetSceneRequest = async (id: number) => {
  const { selected } = derivedBridgeState;
  const { user } = userState;

  if (!selected) return;

  await setScene(user.uuid, selected.macAddress, id);
};

export const useDerivedSnapshot = () => useValtioSnapshot(scenes);
