import { css } from 'styled-components';

export const skeletonAnimation = () => css`
  background: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground};
  background: ${({ theme }) => `
    linear-gradient(110deg, 
    ${theme?.colors?.greys?.adminGreyMedium} 8%, 
    ${theme?.colors?.greys?.adminGreyLightAlt} 18%, 
    ${theme?.colors?.greys?.adminGreyMedium} 33%)`}
  ;
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;
