import {
  AppThemeProvider,
  DefaultThemeWithFonts,
  defaultTheme,
} from '@hqo/react-components-library/dist/molecules/theme';

import { ACTION_STATUSES } from 'shared/consts';
import { AppContentWrapperStyles } from 'components/app-content-wrapper/app-content-wrapper.styles';
import { Container } from 'hocs/shared-styles';
import { CustomFonts } from 'components/custom-fonts';
import React from 'react';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { selectBuildingStatus } from 'store/building/selectors';
import { useBuilding } from 'hooks/use-building.hook';
import { useSelector } from 'react-redux';
import { useViewParams } from 'hooks/use-view-params.hook';
import { ErrorModal } from 'components/error-modal';
import { useGetTheme } from 'hooks/use-get-theme.hook';

interface AppContentWrapperProps {
  children: React.ReactNode;
}

export const AppContentWrapper = ({ children }: AppContentWrapperProps): JSX.Element => {
  const params = useViewParams();
  const buildingStatus = useSelector(selectBuildingStatus);
  const theme = useGetTheme();
  const building = useBuilding();
  const buildingStatusFinished = buildingStatus === ACTION_STATUSES.FULFILLED
    || buildingStatus === ACTION_STATUSES.REJECTED;

  const hasBuilding = building || params.buildingUuid;

  if (theme && (buildingStatusFinished || !hasBuilding)) {
    return (
      <>
        <AppContentWrapperStyles customThemeFonts={theme.fonts} />
        <CustomFonts />
        <AppThemeProvider theme={theme as DefaultThemeWithFonts}>
          {children}
          <ErrorModal />
        </AppThemeProvider>
      </>
    );
  }
  return (
    <Container data-testid="loading-container" isLoading>
      <Spinner size="10em" color={defaultTheme.colors.$greyLight} />
    </Container>
  );
};
