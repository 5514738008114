import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';

import {
  DisplayWrapper,
  DrawerControls,
  DrawerHandle,
  DrawerTitle,
  DrawerTitleSkeleton,
  IconWrapper,
  InvertedSlider,
  PlaceholderControl,
  RoomTitle,
  SliderSkeleton,
  StyledCloseIcon,
  StyledDrawer,
} from 'components/vertical-slider/styles';
import { ShadeOff } from 'components/icons/shade-off';
import { ShadeOn } from 'components/icons/shade-on';
import { ShadeLoading } from 'components/icons/shade-loading';
import { useDerivedRoomNameSnapshot, useDeviceSnapshot } from 'valtioStore';
import {
  fetchActiveDeviceState,
  updateActiveDeviceState,
} from 'valtioStore/device';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

export const ShadeControlSlider = ({ isOpen, onClose, onOpen }: Props): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const { activeDevice, activeDeviceState } = useDeviceSnapshot();
  const [currentLevel, setCurrentLevel] = useState(0);
  const pollingInterval = 45000;

  const room = useDerivedRoomNameSnapshot();

  useEffect(() => {
    if (activeDeviceState?.level !== null) {
      setCurrentLevel(activeDeviceState?.level);
      setLoading(false);
    }

    const interval = setInterval(() => {
      fetchActiveDeviceState();
    }, pollingInterval);

    return () => clearInterval(interval);
  }, [activeDeviceState]);

  const handleChange = useCallback((_event: ChangeEvent<unknown>, newValue: number) => {
    setCurrentLevel(newValue as number);
    updateActiveDeviceState(newValue as number);
  }, []);

  function renderShadeIcon() {
    if (loading) return <ShadeLoading />;
    return currentLevel > 0 ? (
      <ShadeOff data-testid="shade-off" />
    ) : (
      <ShadeOn data-testid="shade-on" />
    );
  }

  return (
    <StyledDrawer onClose={onClose} onOpen={onOpen} open={isOpen} anchor="bottom">
      <DisplayWrapper data-testid="shade-control-slider">
        <DrawerControls>
          <PlaceholderControl />
          <DrawerHandle />
          <StyledCloseIcon onClick={onClose} />
        </DrawerControls>
        {!activeDevice?.name && <DrawerTitleSkeleton />}
        {activeDevice?.name && <DrawerTitle>{activeDevice.name}</DrawerTitle>}
        {!loading && <RoomTitle>{room.name}</RoomTitle>}
        <IconWrapper>{renderShadeIcon()}</IconWrapper>
        {loading && <SliderSkeleton />}
        {!loading && <InvertedSlider value={currentLevel} onChange={handleChange} />}
        {!loading && (
          <Typography
            variant="button"
            style={{ fontSize: '1.5rem' }}
            data-testid="slider-info-value"
          >
            {currentLevel}
            %
          </Typography>
        )}
      </DisplayWrapper>
    </StyledDrawer>
  );
};
