import * as React from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@hqo/react-components-library';

import { EmptyDescription, EmptyTitle, EmptyWrapper } from 'components/devices/styles';

export const Empty = (): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <EmptyWrapper data-testid="empty-state">
      <EmptyTitle>{formatMessage({ id: 'list_devices.no_devices.title' })}</EmptyTitle>
      <EmptyDescription>{formatMessage({ id: 'list_devices.no_devices.description' })}</EmptyDescription>
      <Button
        data-testid="get-started"
        dataCy="basic-button"
        size="m"
        type="button"
        variant="Primary"
        text={formatMessage({ id: 'list_devices.no_devices.get_started' })}
      />
    </EmptyWrapper>
  );
};
