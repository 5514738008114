import { ApiResponse, fetchApiData, postApiData } from './apiService';
import { Room, ControlType } from 'types/Device';
import { Bridge } from 'types/Bridge';
import { Scene } from 'types/Scene';
import { FetchOptions } from '../types/FetchOptions';
import { ActiveDeviceState } from 'valtioStore/device';

const baseUrl = `${process.env.REACT_APP_API_URL}/lutron/lutron-integration`;

export const fetchScenes = async (
  uuid: string,
  macAddress: string,
  deviceType: string = 'Caseta',
): Promise<ApiResponse<Scene[]>> => {
  if (!uuid || !macAddress || !deviceType) {
    throw new Error('Invalid parameters');
  }

  const url = new URL(`${baseUrl}/scenes`);
  url.searchParams.append('uuid', uuid);
  url.searchParams.append('macAddress', macAddress);
  url.searchParams.append('deviceType', deviceType);

  return fetchApiData<Scene[]>(url.toString());
};

export const fetchBridges = async (uuid: string): Promise<ApiResponse<Bridge[]>> => {
  if (!uuid) {
    throw new Error('Invalid parameters');
  }

  const url = new URL(`${baseUrl}/bridges`);
  url.searchParams.append('uuid', uuid);

  return fetchApiData<Bridge[]>(url.toString());
};

export const fetchRoomsWithDevices = async (
  uuid: string,
  macAddress: string,
): Promise<ApiResponse<Room[]>> => {
  if (!uuid || !macAddress) {
    throw new Error('Invalid parameters');
  }

  const url = new URL(`${baseUrl}/rooms-with-devices`);
  url.searchParams.append('uuid', uuid);
  url.searchParams.append('macAddress', macAddress);

  return fetchApiData<Room[]>(url.toString());
};

export const fetchDeviceState = async (
  uuid: string,
  macAddress: string,
  zone: number,
  controlType: string,
): Promise<ApiResponse<ActiveDeviceState>> => {
  if (!uuid || !macAddress || !zone || !controlType) {
    throw new Error('Invalid parameters');
  }

  const url = new URL(`${baseUrl}/device-state`);
  url.searchParams.append('uuid', uuid);
  url.searchParams.append('macAddress', macAddress);
  url.searchParams.append('zone', zone?.toString());
  url.searchParams.append('controlType', controlType);

  return fetchApiData<ActiveDeviceState>(url.toString());
};

export const setScene = async (uuid: string, macAddress: string, id: number) => {
  const url = new URL(`${baseUrl}/activate-scene`);
  const data = {
    uuid,
    macAddress,
    id,
  };
  const options: FetchOptions = {
    body: JSON.stringify(data),
  };
  return postApiData(options)(url.toString());
};

/*
 * Setter for Shade and Light, only level types (using slider)
 */
export const setDeviceState = async (
  uuid: string,
  macAddress: string,
  zone: number,
  controlType: string,
  level: number,
) => {
  const data = {
    uuid,
    macAddress,
    zone,
    controlType,
    level,
  };

  const options: FetchOptions = {
    body: JSON.stringify(data),
  };

  const postReq = postApiData(options);

  const url =
    controlType === ControlType.Dimmed
      ? new URL(`${baseUrl}/set-light`)
      : new URL(`${baseUrl}/set-shade`);

  return postReq(url.toString());
};

/*
 * Setter for Light of a type Switched
 * This one is used for status ON/OFF explicitly (ControlType.Switched)
 */
export const toggleDeviceState = async (
  uuid: string,
  macAddress: string,
  zone: number,
  controlType: string,
  status: 'On' | 'Off',
) => {
  const url = new URL(`${baseUrl}/set-light`);

  const data = {
    uuid,
    macAddress,
    zone,
    controlType,
    status,
  };

  const options: FetchOptions = {
    body: JSON.stringify(data),
  };

  return postApiData(options)(url.toString());
};
