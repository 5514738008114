import * as altBuildingsActions from 'store/alt-buildings/actions';
import * as BuildingActions from 'store/building/actions';
import * as configActions from 'store/config/actions';
import * as errorsActions from 'store/errors/actions';
import * as themeActions from 'store/theme/actions';
import * as userActions from 'store/user/actions';
import * as routerActions from 'store/router/actions';
import * as uiMetadataActions from 'store/ui-metadata/actions';

import { ActionType } from 'typesafe-actions';

const actions = {
  router: routerActions,
  user: userActions,
  theme: themeActions,
  config: configActions,
  errors: errorsActions,
  Building: BuildingActions,
  altBuildings: altBuildingsActions,
  uiMetadata: uiMetadataActions,
};

export type RootAction = ActionType<typeof actions>;
export default actions;
