import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { DisplayWrapper, Title } from 'components/list-devices/styles';

import { Devices } from 'components/devices/devices';
import { Bridges } from 'components/bridges/bridges';
import { Tabs } from 'components/tabs/tabs';
import { Scenes } from 'components/scenes/scenes';
import { Empty } from 'components/devices/empty';
import { AccordionSkeleton, AccordionSummarySkeleton } from 'components/accordion/styles';
import { useDeviceSnapshot } from 'valtioStore';
import { ErrorNotification } from '@hqo/react-components-library/dist/molecules/errorNotification';
import { OfflineWrapper } from 'pages/error/error.styles';

interface RouteParams {
  brand: string;
  [key: string]: string;
}

export const ListDevices = (): JSX.Element => {
  const navigate = useNavigate();

  const { formatMessage } = useIntl();
  const { brand } = useParams<RouteParams>();

  const { hasError } = useDeviceSnapshot();

  const [tab, setTab] = useState(0);
  const tabs = useMemo(() => {
    if (brand === 'caseta') {
      return [
        formatMessage({ id: 'list_devices.rooms' }),
        formatMessage({ id: 'list_devices.scenes' }),
      ];
    }

    // In case we have other brand type like Homeworks for example
    return ['First floor', 'Second floor', 'Third floor', 'Fourth floor'];
  }, [brand, formatMessage]);

  const goBack = useCallback(() => navigate(-1), [hasError]);

  const Loading = useMemo(
    () => (
      <DisplayWrapper>
        <AccordionSkeleton>
          {Array.from({ length: 6 })
            .map((_e, index) => ({ key: `key-${index}` }))
            .map(({ key }) => (
              <AccordionSummarySkeleton key={key} />
            ))}
        </AccordionSkeleton>
      </DisplayWrapper>
    ),
    [],
  );

  return (
    <DisplayWrapper data-testid="list-devices">
      <Bridges />
      <Title data-testid="list-devices-title">{formatMessage({ id: 'list_devices.title' })}</Title>
      {brand === 'empty' && <Empty />}
      {!hasError ? (
        <>
          <Tabs tab={tab} setTab={setTab} tabs={tabs} />
          <React.Suspense fallback={Loading}>
            {tab === 0 && brand !== 'empty' && <Devices />}
          </React.Suspense>
          <React.Suspense fallback={Loading}>
            {tab === 1 && brand !== 'empty' && <Scenes />}
          </React.Suspense>
        </>
      ) : (
        <OfflineWrapper>
          <ErrorNotification
            title="It looks like we're having trouble connecting to your bridge"
            description="Please check your connection to make sure the bridge is powered on and within range"
            buttonText="Go Back"
            onClick={goBack}
          />
        </OfflineWrapper>
      )}
    </DisplayWrapper>
  );
};
