import React, { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';

import {
  ContentWrapper,
  DisplayWrapper,
  DrawerActions,
  DrawerButton,
  DrawerContent,
  DrawerDescription,
  DrawerImageContainer,
  DrawerInfo,
  DrawerLink,
  DrawerTitle,
  GreetingDescription,
  GreetingWrapper,
  StyledDrawer,
  Title,
} from 'components/entry/styles';
import { Typography } from '@material-ui/core';
import { Button, useBooleanState } from '@hqo/react-components-library';
import { SwipeModalRef } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';

export const Entry = (): JSX.Element => {
  const { formatMessage } = useIntl();

  const goToDevices = useCallback(() => {
    const lutronAuthUrl = `${process.env.REACT_APP_API_URL}/lutron/lutron-auth/login`;
    window.location.href = lutronAuthUrl;
  }, []);

  const { value: is1stModalOpen, setTrue: open1stModal, setFalse: close1stModal } = useBooleanState(false);
  const { value: is2ndModalOpen, setTrue: open2ndModal, setFalse: close2ndModal } = useBooleanState(false);
  const modal1Ref = useRef<SwipeModalRef>(null);
  const modal2Ref = useRef<SwipeModalRef>(null);

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const appStoreLink = isIOS
    ? 'https://apps.apple.com/us/app/lutron-app/id886753021'
    : 'https://play.google.com/store/apps/details?id=com.lutron.mmw&authuser=0';

  const handleOpen2ndModalAndClose1stModal = useCallback(() => {
    open2ndModal();
    close1stModal();
  }, [open2ndModal, close1stModal]);

  return (
    <DisplayWrapper data-testid="entry-point">
      <ContentWrapper>
        <Title>{formatMessage({ id: 'entry.title' })}</Title>
        <GreetingWrapper>
          <Typography variant="h6">{formatMessage({ id: 'entry.no_devices.title' })}</Typography>
          <GreetingDescription>{formatMessage({ id: 'entry.no_devices.description' })}</GreetingDescription>
          <Button
            data-testid="get-started"
            dataCy="basic-button"
            size="m"
            type="button"
            variant="Primary"
            text={formatMessage({ id: 'entry.no_devices.get_started' })}
            onClick={open1stModal}
          />
        </GreetingWrapper>
      </ContentWrapper>
      <div>
        <StyledDrawer
          onClose={close1stModal}
          onOpen={open1stModal}
          ref={modal1Ref}
          open={is1stModalOpen}
          anchor="bottom"
        >
          <DrawerContent>
            <DrawerInfo>
              <DrawerTitle>{formatMessage({ id: 'entry.connect.title' })}</DrawerTitle>
              <DrawerDescription>{formatMessage({ id: 'entry.connect.description' })}</DrawerDescription>
              <DrawerImageContainer>
                <img src="/images/bridge.webp" alt="bridge device" />
              </DrawerImageContainer>
            </DrawerInfo>
            <DrawerActions>
              <DrawerButton
                text={formatMessage({ id: 'entry.connect.connect' })}
                onClick={handleOpen2ndModalAndClose1stModal}
              />
              <DrawerLink href="https://www.amazon.com/Lutron-Caseta-L-BDGPRO2-WH-SmartBridge-Programmed/dp/B00Z8AXQCQ">
                {formatMessage({ id: 'entry.connect.i_dont_have_one' })}
              </DrawerLink>
            </DrawerActions>
          </DrawerContent>
        </StyledDrawer>
        <StyledDrawer
          onClose={close2ndModal}
          onOpen={open2ndModal}
          ref={modal2Ref}
          open={is2ndModalOpen}
          anchor="bottom"
        >
          <DrawerContent>
            <DrawerInfo>
              <DrawerTitle>{formatMessage({ id: 'entry.sign_up.title' })}</DrawerTitle>
              <DrawerDescription>{formatMessage({ id: 'entry.sign_up.description' })}</DrawerDescription>
              <DrawerImageContainer>
                <img src="/images/lutron.webp" alt="lutron" width="105" />
              </DrawerImageContainer>
            </DrawerInfo>
            <DrawerActions>
              <DrawerButton text={formatMessage({ id: 'entry.sign_up.sign_in' })} onClick={goToDevices} />
              <DrawerLink href={appStoreLink}>{formatMessage({ id: 'entry.sign_up.download' })}</DrawerLink>
            </DrawerActions>
          </DrawerContent>
        </StyledDrawer>
      </div>
    </DisplayWrapper>
  );
};
