import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AppRoot } from 'components/app-root';
import { AppContentWrapper } from 'components/app-content-wrapper/app-content-wrapper';

import { ErrorPage } from 'pages/error';
import { MiniappSdkDemoPage } from 'pages/miniapp-sdk-demo';
import { Entry } from 'pages/entry/entry';
import { ListDevices } from 'pages/list-devices/list-devices';

export const Router: React.FC = (): JSX.Element => (
  <AppContentWrapper>
    <Routes>
      <Route path="/sdk-demo" element={<MiniappSdkDemoPage />} />
      <Route path="/" element={<AppRoot />} />
      <Route path="/entry" element={<Entry />} />
      <Route path="/list-devices/:brand" element={<ListDevices />} />
      <Route path="/error" element={<ErrorPage />} />
    </Routes>
  </AppContentWrapper>
);
