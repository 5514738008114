import styled from 'styled-components';

import { SwipeableDrawer, Link, Typography } from '@material-ui/core';
import { Button } from '@hqo/react-components-library';

export const DisplayWrapper = styled.div`
  width: 100%;
  max-width: 390px;
  padding-top: 26px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 16px;
`;

export const Title = styled(Typography).attrs({ variant: 'h5' })`
  font-weight: 500 !important;
  color: ${({ theme }) => theme.colors.greys.darkGrey7};
`;

export const GreetingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  padding-top: 160px;
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
`;

export const GreetingDescription = styled(Typography).attrs({ variant: 'body1' })`
  text-align: center;
`;

export const StyledDrawer = styled(SwipeableDrawer)`
  .MuiDrawer-paper {
    border-radius: 8px 8px 0 0;
    height: 70% !important;
  }
`;

export const DrawerContent = styled.div`
  display: flex;
  padding: 24px 16px 0px 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const DrawerTitle = styled(Title)`
  white-space: pre-line;
  text-align: center;
`;

export const DrawerInfo = styled(ContentWrapper)`
  align-items: center;
  justify-content: center;
`;

export const DrawerDescription = styled(Typography).attrs({ variant: 'body1' })`
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  text-align: center;
`;

export const DrawerImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 271px;
  img {
    max-width: 100%;
    height: auto;
  }
`;

export const DrawerActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 12px 0;
  width: 100%;
`;

export const DrawerButton = styled(Button).attrs({
  dataCy: 'basic-button',
  size: 'm',
  type: 'button',
  variant: 'Primary',
})`
  width: 100%;
`;

export const DrawerLink = styled(Link).attrs({
  underline: 'none',
})`
  color: ${({ theme }) => theme.colors.palette.systemGreyDark} !important;
`;
