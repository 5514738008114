import React, { useCallback } from 'react';

import { useBooleanState } from '@hqo/react-components-library';

import {
  Accordion,
  AccordionDetails,
  AccordionItem,
  AccordionItemContent,
  AccordionSummary,
  DisplayWrapper,
} from 'components/accordion/styles';
import { LightBulb } from 'components/icons/light-bulb';
import { Shade } from 'components/icons/shade';
import { useAccordion } from 'components/accordion/useAccordion.hook';
import { LightSwitch } from 'components/light-switch/light-switch';
import { LightControlSlider } from 'components/vertical-slider/light-control/light-control-slider';
import { ShadeControlSlider } from 'components/vertical-slider/shade-control/shade-control-slider';

import { setActiveDevice, useDeviceSnapshot, useRoomsWithDevicesSnapshot } from 'valtioStore';
import { ControlType, ControlTypeKey, Device, Room } from 'types/Device';
import { fetchActiveDeviceState } from '../../valtioStore/device';
import { InfoMessage } from '../info-message/info-message';

type DeviceControllerType = {
  [key in ControlTypeKey]: () => void;
};

export const Devices = (): JSX.Element => {
  const { expanded, handleChange: setExpanded } = useAccordion('panel-0');

  const { items }: { items: Room[] } = useRoomsWithDevicesSnapshot();
  const { activeDevice } = useDeviceSnapshot();

  const {
    value: isLightSwitchModalOpen,
    setTrue: openLightSwitchModal,
    setFalse: closeLightSwitchModal,
  } = useBooleanState(false);

  const {
    value: isLightControllerModalOpen,
    setTrue: openLightControllerModal,
    setFalse: closeLightControllerModal,
  } = useBooleanState(false);

  const {
    value: isShadeControllerModalOpen,
    setTrue: openShadeControllerModal,
    setFalse: closeShadeControllerModal,
  } = useBooleanState(false);

  const deviceController: DeviceControllerType = {
    Dimmed: openLightControllerModal,
    Switched: openLightSwitchModal,
    Shade: openShadeControllerModal,
    ShadeWithTilt: openShadeControllerModal,
    ShadeWithTiltWhenClosed: openShadeControllerModal,
  };

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const handleDeviceController = useCallback(
    (device: Device) => (_e: React.MouseEvent<Element, MouseEvent>) => {
      const { controlType } = device;

      setActiveDevice(device);
      (async () => {
        await fetchActiveDeviceState();
      })();
      deviceController[controlType as ControlType]();
    },
    [],
  );

  if (!items) return null;

  return (
    <DisplayWrapper data-testid="devices-list">
      {items.length > 0 ? (
        items.map((item, index) => (
          <Accordion
            key={item.name}
            expanded={expanded === `panel-${index}`}
            onChange={setExpanded(`panel-${index}`)}
          >
            <AccordionSummary
              aria-controls={`panel-${index}-content`}
              data-testid={`panel-${index}-summary`}
            >
              {item.name}
            </AccordionSummary>
            <AccordionDetails data-testid={`panel-${index}-details`}>
              {item.devices.map((device) => (
                <AccordionItem key={device.name} onClick={handleDeviceController(device)}>
                  <AccordionItemContent>
                    {device.type === 'Shade' ? <Shade /> : <LightBulb />}
                    {device.name}
                  </AccordionItemContent>
                </AccordionItem>
              ))}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <InfoMessage />
      )}
      <div>
        <LightSwitch
          isOpen={isLightSwitchModalOpen}
          onClose={closeLightSwitchModal}
          title={activeDevice?.name}
        />
        <LightControlSlider
          isOpen={isLightControllerModalOpen}
          onClose={closeLightControllerModal}
          onOpen={openLightControllerModal}
          title={activeDevice?.name}
        />
        <ShadeControlSlider
          isOpen={isShadeControllerModalOpen}
          onClose={closeShadeControllerModal}
          onOpen={openShadeControllerModal}
        />
      </div>
    </DisplayWrapper>
  );
};
