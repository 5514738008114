import { AjaxResponse, ajax } from 'rxjs/ajax';

import { AltBuilding } from 'store/alt-buildings/types';
import { BuildingResponse } from 'store/building/types';
import { BuildingTheme } from 'store/theme/types';
import { CurrentUserResponse } from 'store/user/types';
import { OwnerParams } from 'shared/types';
import { UiMetadata } from 'store/ui-metadata/types';
import { VISITOR_REGISTRATION } from 'shared/consts';
import { Observable } from 'rxjs';

export class ApiClient {
  constructor(private baseUrl: string, private authToken?: string | null, private altBuildingUuid?: string | null) {
    // do nothing.
  }

  private get DefaultHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      Authorization: this.authToken,
      ...(this.altBuildingUuid ? { 'alt-building-uuid': this.altBuildingUuid } : {}),
    };
  }

  getCurrentUser(): Observable<AjaxResponse<CurrentUserResponse>> {
    return ajax.get(`${this.baseUrl}/api/auth/current`, this.DefaultHeaders);
  }

  getBuildingTheme(buildingUuid: string): Observable<AjaxResponse<BuildingTheme>> {
    return ajax.get(`${this.baseUrl}/api/buildings/${buildingUuid}/theme`, this.DefaultHeaders);
  }

  getBuilding(buildingUuid: string): Observable<AjaxResponse<BuildingResponse>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/buildings/${buildingUuid}?include=languages`, this.DefaultHeaders);
  }

  getAltBuildings(buildingUuid: string): Observable<AjaxResponse<AltBuilding[]>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/buildings/alt_buildings/${buildingUuid}`, this.DefaultHeaders);
  }

  fetchUiMetadata({ ownerType, ownerId }: OwnerParams): Observable<AjaxResponse<UiMetadata>> {
    return ajax.get(
      `${this.baseUrl}/api/verticals/${VISITOR_REGISTRATION}/${ownerType.toUpperCase()}/${ownerId}/ui-metadata`,
      this.DefaultHeaders,
    );
  }
}
