import { Wrapper } from './error.styles';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'store/router/actions';
import { history } from 'store/router/history';
import { ErrorNotification } from '@hqo/react-components-library/dist/molecules/errorNotification';
import { selectGenericErrorNotifications } from 'store/errors/selectors';
import { hideGenericErrorNotifications } from 'store/errors/actions';
import { getErrorDetails } from 'utils/get-error-details';
import { ErrorType } from 'shared/consts/error-translations';
import { Action } from 'history';

export const ErrorPage = (): JSX.Element => {
  const { formatMessage, messages } = useIntl();
  const errors = useSelector(selectGenericErrorNotifications);
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    if (history.action !== Action.Pop) {
      dispatch(goBack());
      dispatch(hideGenericErrorNotifications());
    }
  }, [dispatch]);
  const { titleTranslationId, descriptionTranslationId, buttonTextTranslationId } = getErrorDetails(
    errors,
    messages,
    ErrorType.GENERIC_ERROR,
  );

  return (
    <Wrapper>
      <ErrorNotification
        title={formatMessage({ id: titleTranslationId })}
        description={formatMessage({ id: descriptionTranslationId })}
        buttonText={formatMessage({ id: buttonTextTranslationId })}
        onClick={handleClick}
      />
    </Wrapper>
  );
};
