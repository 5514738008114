import React, { useCallback } from 'react';

import { StyledTab, StyledTabs } from 'components/tabs/styles';

/* eslint-disable react/no-array-index-key */

interface Props {
  tab: number;
  setTab: (value: number) => void;
  tabs: string[];
}

export const Tabs = ({ tab, setTab, tabs }: Props): JSX.Element => {
  const handleTabChange = useCallback((_event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  }, [setTab]);

  return (
    <StyledTabs
      data-testid="tabs"
      value={tab}
      onChange={handleTabChange}
      aria-label="devices listed by rooms"
      variant={tabs.length > 2 ? 'scrollable' : 'fullWidth'}
    >
      {tabs.map((tabItem, index) => (
        <StyledTab
          key={index}
          label={tabItem}
          data-testid={`tab-${index}`}
        />
      ))}
    </StyledTabs>
  );
};
