import { setUser as setSentryUser } from '@sentry/react';
import { useEffect } from 'react';
import { useSentryAnalytics } from 'hooks/use-sentry-analytics.hook';

export const useAnalytics = (): void => {
  const analytics = useSentryAnalytics();

  useEffect(() => {
    setSentryUser(analytics);
  }, [analytics]);
};
