import React, { useCallback, useEffect, useState } from 'react';

import {
  DrawerContent,
  DrawerTitle,
  RoomTitle,
  StyledDrawer,
  StyledSwitch,
} from 'components/light-switch/styles';

import { useDeviceSnapshot, useDerivedRoomNameSnapshot } from 'valtioStore';
import { fetchActiveDeviceState, toggleActiveDeviceState } from 'valtioStore/device';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

export const LightSwitch = ({ isOpen, onClose, title }: Props): JSX.Element => {
  const [checked, setChecked] = useState(false);

  const { activeDeviceState } = useDeviceSnapshot();
  const [loading, setLoading] = useState(true);
  const pollingInterval = 45000;

  const room = useDerivedRoomNameSnapshot();

  useEffect(() => {
    if (activeDeviceState?.on !== null) {
      setChecked(activeDeviceState?.on);
      setLoading(false);
    }

    const interval = setInterval(() => {
      fetchActiveDeviceState();
    }, pollingInterval);

    return () => clearInterval(interval);
  }, [activeDeviceState]);

  const handleChange = useCallback((_event: React.ChangeEvent<unknown>, newValue: boolean) => {
    setChecked(newValue as boolean);
    toggleActiveDeviceState(newValue as boolean);
  }, []);

  return (
    <StyledDrawer onClose={onClose} open={isOpen} anchor="bottom">
      <DrawerContent>
        <DrawerTitle>{title}</DrawerTitle>
        <RoomTitle>{room.name}</RoomTitle>
        {!loading && (
          <StyledSwitch
            data-testid="light-switch"
            checked={checked}
            onChange={handleChange}
            name="checked"
          />
        )}
      </DrawerContent>
    </StyledDrawer>
  );
};
