import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  height: 100vh;
  width: 100vw;
`;

export const OfflineWrapper = styled(Wrapper)`
  && {
    height: auto;
    width: auto;
  }
`;
