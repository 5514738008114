import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';

import {
  DisplayWrapper,
  DrawerControls,
  DrawerHandle,
  DrawerTitle,
  RoomTitle,
  DrawerTitleSkeleton,
  IconWrapper,
  PlaceholderControl,
  Slider,
  SliderSkeleton,
  StyledCloseIcon,
  StyledDrawer,
} from 'components/vertical-slider/styles';
import { LightBulbOff } from 'components/icons/light-bulb-off';
import { LightBulbOn } from 'components/icons/light-bulb-on';
import { LightBulbLoading } from 'components/icons/light-bulb-loading';
import { useDerivedRoomNameSnapshot, useDeviceSnapshot } from 'valtioStore';
import { fetchActiveDeviceState, updateActiveDeviceState } from 'valtioStore/device';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  title: string;
}

export const LightControlSlider = ({ isOpen, onClose, onOpen, title }: Props): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const { activeDeviceState } = useDeviceSnapshot();
  const [currentLevel, setCurrentLevel] = useState(0);
  const pollingInterval = 45000;

  const room = useDerivedRoomNameSnapshot();

  useEffect(() => {
    if (activeDeviceState?.level !== null) {
      setCurrentLevel(activeDeviceState?.level);
      setLoading(false);
    }

    const interval = setInterval(() => {
      fetchActiveDeviceState();
    }, pollingInterval);

    return () => clearInterval(interval);
  }, [activeDeviceState]);

  const handleChange = useCallback((_event: React.ChangeEvent<unknown>, newValue: number) => {
    setCurrentLevel(newValue as number);
    updateActiveDeviceState(newValue as number);
  }, []);

  return (
    <StyledDrawer onClose={onClose} onOpen={onOpen} open={isOpen} anchor="bottom">
      <DisplayWrapper data-testid="light-control-slider">
        <DrawerControls>
          <PlaceholderControl />
          <DrawerHandle />
          <StyledCloseIcon onClick={onClose} />
        </DrawerControls>
        {loading && <DrawerTitleSkeleton />}
        {!loading && <DrawerTitle>{title}</DrawerTitle>}
        {!loading && <RoomTitle>{room.name}</RoomTitle>}
        <IconWrapper>
          {loading && <LightBulbLoading />}
          {!loading && currentLevel > 0 && <LightBulbOn />}
          {!loading && currentLevel === 0 && <LightBulbOff />}
        </IconWrapper>
        {loading && <SliderSkeleton />}
        {!loading && <Slider value={currentLevel} onChange={handleChange} />}
        {!loading && (
          <Typography variant="button" style={{ fontSize: '1.5rem' }}>
            {currentLevel}
            %
          </Typography>
        )}
      </DisplayWrapper>
    </StyledDrawer>
  );
};
