import { proxy, useSnapshot as useValtioSnapshot } from 'valtio';
import { derive, devtools } from 'valtio/utils';

import { derivedBridgeState, userState } from 'valtioStore';
import {
  fetchDeviceState,
  fetchRoomsWithDevices,
  setDeviceState,
  toggleDeviceState,
} from 'services/lutronService';
import { Device, Room } from 'types/Device';
import { debounce } from '@material-ui/core';

const debounceTimeout = 1000;

export type ActiveDeviceState = {
  level: number;
  on: boolean;
};

export const state = proxy<{
  activeDevice: Device | null;
  activeDeviceState: ActiveDeviceState;
  hasError: boolean;
}>({
  activeDevice: null,
  activeDeviceState: { level: null, on: null },
  hasError: false,
});

export const setActiveDevice = (device: Device) => {
  state.activeDevice = device;
};

export const updateActiveDeviceState = debounce(async (level: number) => {
  state.activeDeviceState.level = level;

  const { selected } = derivedBridgeState;
  const { user } = userState;

  if (selected && state.activeDevice) {
    await setDeviceState(
      user.uuid,
      selected.macAddress,
      state.activeDevice.zone,
      state.activeDevice.controlType,
      level,
    );
  }
}, debounceTimeout);

export const toggleActiveDeviceState = debounce(async (status: boolean) => {
  state.activeDeviceState.on = status;

  const { selected } = derivedBridgeState;
  const { user } = userState;

  if (selected && state.activeDevice) {
    await toggleDeviceState(
      user.uuid,
      selected.macAddress,
      state.activeDevice.zone,
      state.activeDevice.controlType,
      (status && 'On') || 'Off',
    );
  }
}, debounceTimeout);

export const fetchActiveDeviceState = async () => {
  const { selected } = derivedBridgeState;
  const { user } = userState;

  if (!selected || !state.activeDevice) return;

  const { data } = await fetchDeviceState(
    user.uuid,
    selected.macAddress,
    state.activeDevice.zone,
    state.activeDevice.controlType,
  );

  const newState: ActiveDeviceState = {
    ...state.activeDeviceState,
    ...data,
  };

  state.activeDeviceState = newState;
};

export const roomsWithDevices = derive({
  items: async (get) => {
    const { selected } = get(derivedBridgeState);
    const { user } = get(userState);

    if (!selected) return [];

    const { data, error } = await fetchRoomsWithDevices(user.uuid, selected.macAddress);
    if (error) {
      state.hasError = true;
      return []; // as data may be undefined in this case, which will break the reactivity
    }
    state.hasError = false;
    return data;
  },
});

/*
 * @returns Name of the room of the currently active device (shown as subtitle)
 * - It is identified by unique zone property
 */
export const roomName = derive({
  name: (get) => {
    if (!get(state).activeDevice) return null;
    const { items } = roomsWithDevices as { items: { value: Room[] } };
    const { zone } = get(state).activeDevice;
    const room = items.value.find((r) => r.devices.some((device) => device.zone === zone));
    return room.name;
  },
});

export const useSnapshot = () => useValtioSnapshot(state);
export const useDerivedSnapshot = () => useValtioSnapshot(roomsWithDevices);
export const useDerivedRoomNameSnapshot = () => useValtioSnapshot(roomName);

devtools(state, { name: 'Device State', enabled: true });
