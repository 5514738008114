import * as React from 'react';
import styled from 'styled-components';

import MuiAccordion, { AccordionProps } from '@material-ui/core/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { CaretDownSharp } from '@hqo/react-components-library';
import { Typography } from '@material-ui/core';
import { skeletonAnimation } from 'components/skeleton/styles';

/* eslint-disable react/jsx-props-no-spreading */

export const DisplayWrapper = styled.div`
  padding: 24px 16px 10px 16px;
  height: 65vh;
  overflow: scroll;
`;

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion elevation={0} square {...props} />
))(() => ({
  border: 0,
  '&::before': {
    display: 'none',
  },
  'margin-bottom': '16px',
}));

const StyledCaretDownSharp = styled(CaretDownSharp)`
  color: ${({ theme }) => theme.colors.$greyDark};
  height: 12px;
  width: 12px;
`;

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<StyledCaretDownSharp />} {...props} />
))(({ theme }) => ({
  'border-radius': '8px !important',
  background: theme.colors.universal.white,
  'box-shadow': '0px 2px 10px 0px rgba(54, 54, 54, 0.20)',
  'min-height': '56px !important',
  'max-height': '56px !important',
  fontWeight: 500,
  color: `${theme.colors.palette.systemBlack} !important`,
}));

export const AccordionSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 16px;
`;

export const AccordionSummarySkeleton = styled.div`
  height: 56px;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground};
  ${skeletonAnimation};
`;

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '16px 0 0 16px !important',
  display: 'flex',
  'flex-direction': 'column',
  'align-items': 'flex-start',
  gap: '16px',
  'align-self': 'stretch',
}));

export const AccordionItem = styled.button`
  width: 100%;
  height: 56px;
  border: 0;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.universal.white};
  padding: 16px;

  /* New Shadow */
  box-shadow: 0px 2px 10px 0px rgba(54, 54, 54, 0.2);
`;

export const AccordionItemContent = styled(Typography)`
  display: flex;
  align-items: start;
  gap: 8px;
  font-weight: 500 !important;
  color: ${({ theme }) => theme.colors.palette.systemBlack};
`;
