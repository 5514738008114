import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const DisplayWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography).attrs({ variant: 'h5' })`
  font-weight: 500 !important;
  color: ${({ theme }) => theme.colors.greys.darkGrey7};
  padding: 24px 16px;
`;
