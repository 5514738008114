import { ConfigState } from './types';
import qs from 'qs';
import { getDefaultApiUrl } from 'store/config/get-default-url.util';
import { Config } from './enums';

export function getInitialState(): ConfigState {
  const queryParams = qs.parse(window.location.search) as unknown as ConfigState;

  return {
    apiUrl: queryParams.apiUrl ?? localStorage.getItem(Config.API_URL_LS_KEY) ?? getDefaultApiUrl(),
    appBrand: queryParams.appBrand ?? localStorage.getItem(Config.APP_BRAND_LS_KEY) ?? null,
    buildingUuid: queryParams.buildingUuid ?? localStorage.getItem(Config.BUILDING_UUID_LS_KEY) ?? null,
    locale: queryParams.locale ?? localStorage.getItem(Config.LOCALE_LS_KEY) ?? null,
    authToken: queryParams.authToken ?? localStorage.getItem(Config.AUTH_TOKEN_LS_KEY) ?? null,
  };
}

export const initialState: ConfigState = getInitialState();
